import { isServer } from '@sitecore-jss/sitecore-jss';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import useHeaderAdsVisible from '../hooks/useHeaderAdsVisible.js';
import { useIsMobile } from '../hooks/useIsMobile.js';

import CTA from '../cta/CTA';
import RoutableLink from '../routable-link';
import { getIcon } from '../utils/icon.js';
import {
  AccordionContent,
  DesktopNav,
  Header,
  HoverContainer,
  MobileWrap,
  SectionTitle,
  SubNavigationComponent,
} from './SubNavigation.styles.js';

const SubNavigation = ({ fields, params }) => {
  const { logo, logoUrl, cta, items } = fields ?? {};
  const {
    theme: siteTheme,
    spaceMultiplierMobile = '1',
    spaceMultiplierDesktop = '1',
  } = params ?? {};

  const isMobileLayout = () => {
    if (isServer()) return;

    return items.length > 4
      ? useIsMobile('desktopSmall')
      : useIsMobile('tabletPortrait');
  };
  const headerAdsVisible = useHeaderAdsVisible();
  const shouldReduceMotion = useReducedMotion();
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [titleHoveredIndex, setTitleHoveredIndex] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const isMobile = isMobileLayout();
  const [isMounted, setIsMounted] = useState(false);

  const accordionRefCallback = useCallback((ref) => {
    if (ref) {
      disableBodyScroll(ref);
    } else {
      clearAllBodyScrollLocks();
    }

    return () => clearAllBodyScrollLocks();
  }, []);

  const handleSetHeaderHeight = () => {
    const headerAdsEl = document.querySelector('#header-ads');
    const headerEl = document.querySelector('header.main-header');

    setHeaderHeight(
      (headerAdsEl?.clientHeight ?? 0) + (headerEl?.clientHeight ?? 0)
    );
  };

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleSetHeaderHeight);
    handleSetHeaderHeight();
  }, [headerAdsVisible]);

  useEffect(() => {
    if (!isMobile) setAccordionOpen(null);
  }, [isMobile]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const headerEl = document.querySelector('header.main-header');
    headerEl.classList[accordionOpen ? 'add' : 'remove']('fixed');
  }, [accordionOpen]);

  return (
    <SubNavigationComponent
      className="sub-navigation"
      accordionOpen={accordionOpen}
      headerHeight={headerHeight}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      isMobileLayout={isMobile && isMounted}
    >
      <Header siteTheme={siteTheme} isMobileLayout={isMobile && isMounted}>
        {logo?.value?.src && logoUrl?.value?.href && (
          <RoutableLink
            editable={false}
            field={{
              value: {
                href: logoUrl?.value?.href,
                linktype: logoUrl?.value?.linktype,
              },
            }}
          >
            <img alt={logo.value.alt} src={logo.value.src} />
          </RoutableLink>
        )}

        {isMobile && isMounted && (
          <motion.button
            initial={{ rotate: 0 }}
            animate={{ rotate: accordionOpen ? '180deg' : 0 }}
            exit={{ rotate: 0 }}
            transition={{ duration: shouldReduceMotion ? 0 : 0.3 }}
            onClick={() => setAccordionOpen(!accordionOpen)}
          >
            {getIcon('thickArrow')}
          </motion.button>
        )}
      </Header>

      {isMobile && isMounted && (
        <MobileWrap>
          <AnimatePresence exitBeforeEnter>
            {accordionOpen && (
              <motion.div
                style={{ overflow: 'hidden' }}
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: 'auto',
                  opacity: 1,
                }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: shouldReduceMotion ? 0 : 0.3 }}
              >
                <AccordionContent
                  hasMargin={!cta?.value?.text}
                  ref={accordionRefCallback}
                >
                  <ul>
                    {items.map(({ fields: { title, links, link } = {} }) => (
                      <li key={title?.value}>
                        {title.value && (
                          <div>
                            <SectionTitle
                              as={link?.value?.href ? 'a' : 'p'}
                              href={link?.value?.href}
                              className={link?.value?.href ? 'link' : undefined}
                            >
                              {title.value}
                            </SectionTitle>
                          </div>
                        )}
                        {links.value?.length > 0 && <ul>
                          {links.value.map(({ text, linktype, href }) => (
                            <li key={`${title?.value}-${text}`}>
                              <RoutableLink
                                editable={false}
                                field={{
                                  value: {
                                    href,
                                    text,
                                    linktype,
                                  },
                                }}
                                onClick={() => {
                                  dataLayerEvent('navSecondary', {
                                    clickText: text,
                                    title: title.value,
                                  });
                                }}
                              />
                              {getIcon('arrowLong')}
                            </li>
                          ))}
                        </ul>}
                      </li>
                    ))}
                  </ul>
                </AccordionContent>
              </motion.div>
            )}
          </AnimatePresence>
          {accordionOpen && (
            <AnimatePresence>
              {cta?.value?.text && (
                <motion.div
                  className="cta-container"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <CTA
                    variant={siteTheme === 'LME' ? 'blackF' : 'whiteF'}
                    label={cta?.value?.text}
                    link={cta?.value}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </MobileWrap>
      )}

      {!isMobile && isMounted && (
        <>
          <DesktopNav>
            <ul>
              {items.map(({ fields: { title, links, link } = {} }, index) => (
                <li
                  key={title?.value}
                  onMouseEnter={() => setTitleHoveredIndex(index)}
                  onMouseLeave={() => setTitleHoveredIndex(null)}
                >
                  {title?.value && (
                    <div>
                      <SectionTitle
                        as={link?.value?.href ? 'a' : 'p'}
                        href={link?.value?.href}
                        className={`
                          ${index === titleHoveredIndex ? 'active' : undefined}
                          ${link?.value?.href ? 'link' : undefined}
                        `}
                        siteTheme={siteTheme}
                        tabIndex={0}
                        onKeyUp={(event) =>
                          event.key === 'Enter' && setTitleHoveredIndex(index)
                        }
                      >
                        {title.value}
                      </SectionTitle>
                    </div>
                  )}

                  <AnimatePresence>
                    {index === titleHoveredIndex && links.value?.length > 0 && (
                      <HoverContainer
                        initial={{ height: 0, y: -40, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1, y: 0 }}
                        transition={{
                          duration: shouldReduceMotion ? 0 : 0.35,
                        }}
                        exit={{ height: 0, opacity: 0, y: 0 }}
                        key="container"
                        siteTheme={siteTheme}
                      >
                        {links.value.map(({ text, linktype, href }) => (
                          <div key={`${title.value}-${text}`}>
                            <RoutableLink
                              editable={false}
                              field={{
                                value: {
                                  href,
                                  text,
                                  linktype,
                                },
                              }}
                              onClick={() => {
                                dataLayerEvent('navSecondary', {
                                  clickText: text,
                                  title: title.value,
                                });
                              }}
                            />
                            {getIcon('arrowLong')}
                          </div>
                        ))}
                      </HoverContainer>
                    )}
                  </AnimatePresence>
                </li>
              ))}
            </ul>
          </DesktopNav>
          {cta?.value?.text && (
            <CTA
              variant={siteTheme === 'LME' ? 'blackF' : 'whiteF'}
              label={cta?.value?.text}
              link={cta?.value}
            />
          )}
        </>
      )}
    </SubNavigationComponent>
  );
};

SubNavigation.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
};

export default SubNavigation;
