import React from 'react';
import SubNavigationComponent from 'lmel-components/core/sub-navigation';
import ErrorBoundary from 'lmel-components/core/error-boundary';

const SubNavigation = (props) => {
  return (
    <ErrorBoundary>
      <SubNavigationComponent {...props} />
    </ErrorBoundary>
  );
};

export default SubNavigation;
