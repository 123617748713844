import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import has from 'lodash/has';

const RoutableLink = ({ field, editable = true, forceExternal, children, ...otherProps }) => {
  const hasValidHref = has(field, 'value.href');
  const isEditing = editable && field?.editable;

  const linkProps = Object.assign({}, otherProps);
  delete linkProps.header;
  delete linkProps.clickable;
  delete linkProps.variant;

  if (hasValidHref && !isEditing && !forceExternal) {
    const { value } = field;

    if (value.linktype === 'internal' && value.href.charAt() === '/') {
      return (
        <RouterLink to={value.href} {...linkProps}>
          {children || value.text}
        </RouterLink>
      );
    }
  }

  return (
    <Link field={field} editable={editable} showLinkTextWithChildrenPresent={false} {...linkProps}>
      {children}
    </Link>
  );
};

RoutableLink.propTypes = {
  editable: PropTypes.bool,
  forceExternal: PropTypes.bool,
  field: PropTypes.shape({
    editable: PropTypes.object,
    value: PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string,
      target: PropTypes.string,
      text: PropTypes.string,
      linktype: PropTypes.string.isRequired,
    }),
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
};

RoutableLink.defaultProps = {
  editable: true,
};

export default RoutableLink;
