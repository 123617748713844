import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { motion } from 'framer-motion';

const mapColoursByTheme = (theme, siteTheme) => {
  switch(siteTheme) {
    case 'LMF':
      return theme.colors.named['positive-pink'];
    case 'LME':
      return theme.colors.named.aqua;
    case 'HoS':
      return theme.colors.named.squash;
    default:
      return undefined;
  }
};

export const SubNavigationComponent = styled.section`
  position: ${({ accordionOpen }) => (accordionOpen ? 'fixed' : 'relative')};
  top: ${({ accordionOpen, headerHeight }) =>
    accordionOpen ? `${headerHeight}px` : 0};
  bottom: ${({ theme: { px } }) => px(-26)};
  overflow-y: auto;
  background-color: ${({ theme: { colors } }) =>
    colors.named['reassuring-navy']};
  color: ${({ theme: { colors } }) => colors.named.white};
  padding: 0 ${({ theme: { px } }) => px(20)};
  width: 100%;
  z-index: 99;
  margin-bottom: ${({ theme: { space }, spaceMultiplierMobile }) =>
    space(spaceMultiplierMobile)};
  overscroll-behavior: contain;

  ${breakpoints.tabletLandscape} {
    padding: 0 ${({ theme: { px } }) => px(61)};
  }

  ${({ isMobileLayout }) =>
    !isMobileLayout &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 2;
      margin-bottom: ${({ theme: { space }, spaceMultiplierDesktop }) =>
        space(spaceMultiplierDesktop)};
      overflow: unset;
    `};

  img {
    width: 100%;
  }

  li::marker {
    content: none;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 77px;
  background-color: ${({ theme: { colors } }) =>
    colors.named['reassuring-navy']};
  position: sticky;
  top: 0;
  z-index: 100;

  ${({ isMobileLayout }) =>
    !isMobileLayout &&
    css`
      min-height: 90px;
      width: auto;
    `};

  a {
    display: flex;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;

    svg {
      path {
        fill: ${({ siteTheme, theme: { colors } }) =>
          siteTheme === 'LME' ? colors.secondary[1000] : colors.secondary[700]};
      }
    }
  }
`;

export const AccordionContent = styled.div`
  -webkit-overflow-scrolling: touch;
  margin-top: ${({ theme: { px } }) => px(5)};
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding-bottom: ${({ theme: { px } }) => px(47)}; */
  overflow-y: auto;
  overflow-x: hidden;

  > ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-inline-start: 0;
    padding-bottom: ${({ hasMargin, theme: { px } }) =>
      hasMargin ? px(30) : 0};

    li {
      &:not(:last-child) {
        margin-bottom: ${({ theme: { px } }) => px(25)};
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${({ theme: { px } }) => px(25)};

        p {
          margin: 0;
        }
      }

      p, a {
        ${({ theme }) => theme.setFont('subNavigationText')};
        font-family: ${(props) => props.theme.typography.typefaces.headings};
      }

      p {
        margin: 0 0 ${({ theme: { px } }) => px(25)};
      }

      svg {
        transform: scale(1.2);

        path {
          fill: ${({ theme: { colors } }) => colors.grays[300]};
        }
      }

      ul {
        margin-top: ${({ theme: { px } }) => px(5)};
        padding-inline-start: ${({ theme: { px } }) => px(40)};

        li {
          display: flex;
          justify-content: space-between;

          a {
            margin: 0;
            text-decoration: none;
            color: ${({ theme: { colors } }) => colors.named.white};
            width: 100%;
          }
        }
      }
    }
  }
`;

export const HoverContainer = styled(motion.div)`
  position: absolute;
  top: ${({ theme: { px } }) => px(90)};
  background-color: ${({ theme: { colors } }) =>
    colors.named['reassuring-navy']};
  min-width: 350px;
  z-index: 1;
  padding: ${({ theme: { px } }) =>
    px({
      top: 30,
      left: 25,
      bottom: 20,
      right: 25,
    })};

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      margin: 0;
      text-decoration: none;
      color: ${({ theme: { colors } }) => colors.named.white};
      font-weight: 400;
      ${({ theme }) => theme.setFont('subNavigationText')};
      width: 100%;
    }

    svg {
      transform: scale(1.2);

      path {
        fill: ${({ theme: { colors } }) => colors.grays[300]};
      }
    }

    &:hover {
      a {
        color: ${({ theme, siteTheme }) => mapColoursByTheme(theme, siteTheme)};
      }

      svg {
        path {
          fill: ${({ theme, siteTheme }) =>
            mapColoursByTheme(theme, siteTheme)};
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ${({ theme: { px } }) => px(15)};
    }
  }
`;

export const DesktopNav = styled.div`
  height: 100%;
  margin: 0 auto;

  > ul {
    padding-inline-start: 0;
    display: flex;
    margin: 0;
    height: 100%;
    gap: 40px;

    li {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;

      > div {
        height: 100%;

        p, a {
          display: flex;
          align-items: center;
          height: 100%;
          font-size: ${({ theme: { px } }) => px(12)};
          font-weight: 700;
        }

        div {
          p, a {
            font-size: ${({ theme: { px } }) => px(14)};
          }
        }
      }
    }
  }
`;

export const SectionTitle = styled.p`
  ${({ theme }) => theme.setFont('navigation')};
  color: ${({ theme: { colors } }) => colors.named.white};
  position: relative;
  font-weight: 600;
  font-family: ${(props) => props.theme.typography.typefaces.headings};
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 0;

  &.link {
    cursor: pointer;
    text-decoration: none;
  }

  &.active:not(.link) {
    cursor: default;
  }

  &.active {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: ${({ theme: { px } }) => px(27)};
      width: 100%;
      height: ${({ theme: { px } }) => px(6)};
      background-color: ${({ theme, siteTheme }) =>
        mapColoursByTheme(theme, siteTheme)};
    }
  }
`;

export const MobileWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 77px);
  overflow: hidden;

  .cta-container {
    margin-top: auto;

    > a {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: ${({ theme: { px } }) => px(30)};
      margin-bottom: ${({ theme: { px } }) => px(40)};

      span {
        min-width: calc(100% - 11px) !important; // TODO: Fix
        font-size: ${({ theme: { px } }) => px(22)};
      }
    }
  }
`;
